import React from "react";
import ContentContainer from "../container/ContentContainer";

const SatisfiedCustomerPlaceholder = () => {
  return (
    <ContentContainer className="w-full max-w-6xl">
      <h2 className="text-center mt-32">SATISFIED CUSTOMERS</h2>

      <div className="each-fade mb-14 py-4 lg:py-0 h-50vh md:h-96 flex flex-col items-center justify-center px-2 lg:px-14 text-center text-xs lg:text-base">
          <p className="italic text-left">
            "We have worked with National Wood Products, Inc. for over 15 years.
            NWP has always taken our business seriously, even though in the
            beginning, we were not a very big account. They have helped us grow
            by being a great supplier for our lumber needs. They stock various
            products and have an extensive inventory. NWP is always looking for
            ways to partner with us, and deliver what we need, when we need it,
            allowing us to operate JIT when it comes to our supply. National
            Wood Products has the experience to help their customers lower
            overall costs by providing cost analysis on varying grades and
            yields. They are able to help business owners, like myself, make
            educated decisions and concentrate on their other production
            concerns. They always make us feel as if we are their only customer.
            It’s amazing the level of service and willingness to help that they
            consistently deliver every day."
          </p>
          <br />
          <p className="inline">
            <strong>Bob Marcynysyn</strong>, Owner, Avalon Shutters
          </p>
        </div>
    </ContentContainer>
  );
};

export default SatisfiedCustomerPlaceholder;
