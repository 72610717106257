import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContentContainer from "../../container/ContentContainer";

const ValuedSuppliers = () => {
  const suppliers = useStaticQuery(graphql`
    query suppliers {
      prismicSuppliers {
        data {
          body {
            ... on PrismicSuppliersBodyImageGallery {
              id
              primary {
                supplier_gallery_title {
                  text
                }
              }
              items {
                supplier_logo {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                        quality: 100
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                supplier_name {
                  text
                }
                supplier_url {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const gallery = suppliers?.prismicSuppliers?.data.body[0];
  return (
    <>
      {gallery && (
        <ContentContainer
          containerClassName="mb-0 sm:mb-32"
          className="flex flex-col items-center justify-center max-w-full sm:mx-5 text-center"
        >
          <h2>
            {
              suppliers.prismicSuppliers.data.body[0].primary
                .supplier_gallery_title.text
            }
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-5 gap-y-10 sm:gap-y-20 mt-10 sm:mt-24">
            {gallery.items.map((supplier, index) => {
              const image = getImage(supplier.supplier_logo.localFile);
              return (
                <div
                  key={`supplier${index}`}
                  className="flex flex-col items-center justify-center"
                >
                  <a
                    href={supplier.supplier_url.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <GatsbyImage
                      image={image}
                      alt={supplier.supplier_name.text}
                      className="w-32 sm:w-32 md:w-40 lg:w-52 xl:w-72 sm:h-32 "
                      objectFit="contain"
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </ContentContainer>
      )}
    </>
  );
};

export default ValuedSuppliers;
