import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const PreviewItem = ({
  title,
  description,
  url,
  linkText,
  imageAlign,
  backgoundColor,
  className,
  contentAlign,
  image,
  children,
  imageFit,
  imageClassName,
  linkClassName
}) => {
  return (
    <div
      className={`flex flex-col items-stretch ${backgoundColor} ${
        imageAlign === "right" ? "md:flex-row-reverse" : "md:flex-row"
      } ${className}`}
    >
      <div className="w-full md:w-55%">
        <GatsbyImage as="div" image={image} objectFit={imageFit} className={imageClassName ? imageClassName : "w-full h-full"} alt="Peview" />
      </div>
      <div
        className={`w-full md:w-6/12 p-10 sm:p-16 flex flex-col justify-center space-y-5 ${
            contentAlign === "right" ? "text-right" : "text-left"
        }`}
      >
        <h2>{title}</h2>
        {description && <h3 className="pb-10 font-bold">{description}</h3>}
        <div>{children}</div>
        <Link className={linkClassName} to={url}>{linkText}</Link>
      </div>
    </div>
  );
};

export default PreviewItem;
