import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import ContentContainer from "../container/ContentContainer";
import PreviewItem from "./PreviewItem";

const ProductFeature = ({ className, titleClassName, contentClassName }) => {
  const images = useStaticQuery(graphql`
    {
      stevensWood: file(relativePath: { eq: "new-textured-melamine.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      oneskin: file(relativePath: { eq: "oneskin-related-section.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      kronospan: file(relativePath: { eq: "kronospan-laminates.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
    }
  `);
  const contentData = [
    {
      title: "Thermally Fused Laminate Panels from Kronospan",
      content:
        "Kronospan: A World of Design Possibilities ™. A range of contemporary, future oriented and high-quality wood-based panels, created to inspire designers, architects and craftsmen and bring originality and innovation to new interior projects around the world.",
      content2: "Our Melamine-faced panels [MF-PB or MF-MDF] are created by high pressure heat, bonding impregnated paper to particleboard or MDF to provide a highly wear and water-resistant product surface finish. This product is used for a wide range of applications, including kitchen, bathroom, bedroom, and office furniture, in a variety of fashionable designs and textures.",
      link: "Learn More",
      url: "products/melamine-panels",
      image: images.kronospan,
    },
    {
      title: "Lacquered Boards from OneSkin",
      content:
        "OneSkin has been created to supply worldwide markets with high quality lacquered boards that open new possibilities in the furniture industry, interior decorations, paneling, and other architectural purposes.",
      link: "Learn More",
      url: "products/lacquered-boards",
      image: images.oneskin,
    },
    {
      title: "Textured Melamine from StevensWood®",
      content:
        "These “Euro-style” colored melamine panels give the look of real wood color, texture, and grain but it’s more economical, lower maintenance plus scratch and water resistant sheets and boards.",
      link: "Learn More",
      url: "products/textured-melamine/",
      image: images.stevensWood,
    },
  ];

  return (
    <ContentContainer
      className="flex flex-col w-full lg:w-1000px mb-20"
      titleText="Featured Products"
      titleClassName="px-4 lg:px-0 my-20 text-3xl"
    >
      <div className="hidden sm:flex flex-col space-y-5 ">
        {contentData.map((d, index) => {
          const image = getImage(d.image);
          const imageAlign = index % 2 === 0 ? "right" : "left";
          const contentAlign = index % 2 === 0 ? "left" : "right";
          return (
            <PreviewItem
              className="text-semibold"
              key={`ProductFeature${index}`}
              title="FEATURED"
              description={d.title}
              url={d.url}
              linkText={d.link}
              backgoundColor="bg-gray2"
              titleClassName="pb-10 text-bold"
              image={image}
              imageAlign={imageAlign}
              imageFit="cover"
              contentAlign={contentAlign}
            >
              <p className={contentClassName}>{d.content}</p>
              {d.content2 && <p className={`pt-4 ${contentClassName}`}>{d.content2}</p>}
            </PreviewItem>
          );
        })}
      </div>

      <div className="flex sm:hidden flex-col space-y-5 ">
        <div className="w-full text-center py-4"><h2>Featured</h2></div>
        {contentData.map((d, index) => {
          const image = getImage(d.image);
          return (
            <PreviewItem
              className="text-semibold"
              key={`ProductFeature${index}`}
              description={d.title}
              url={d.url}
              linkText={d.link}
              backgoundColor="bg-gray2"
              titleClassName="pb-10 text-bold"
              image={image}
              imageAlign="left"
              imageFit="cover"
              contentAlign="left"
              linkClassName="text-right"
            >
              <p className={contentClassName}>{d.content}</p>
              {d.content2 && <p className={`pt-2 ${contentClassName}`}>{d.content2}</p>}
            </PreviewItem>
          );
        })}
      </div>
    </ContentContainer>
  );
};

export default ProductFeature;
