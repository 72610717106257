import React from "react"
import ContentContainer from "../container/ContentContainer"
import ButtonArrow from "../controls/ButtonArrow"

const Hiring = () =>
{
    return (
        <ContentContainer className="bg-actionBlue w-full max-w-full flex items-center justify-center">
            <div className="w-full max-w-7xl flex justify-end items-center py-4">
                <h2 className="text-white pr-10">WE ARE HIRING</h2>
                <div>
                    <ButtonArrow to="/jobs" className="bg-white text-actionBlue p2 text-4xl" ariaLabel="Jobs" />
                </div>
            </div>
        </ContentContainer>
    )
}

export default Hiring;