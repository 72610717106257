import React from "react";
import ContentContainer from "../container/ContentContainer";
import PreviewItem from "./PreviewItem";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PreviewAbout = ({ className, titleClassName, contentClassName }) => {
  const tree = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "tree.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
            height: 200
          )
        }
      }
    }
  `);

  const image = getImage(tree.image);

  return (
    <ContentContainer className="my-10">
      <PreviewItem
        title="About"
        description=""
        url="/about"
        linkText="Our Story"
        backgoundColor="bg-transparent"
        titleClassName="px-4 lg:px-0 my-20 text-3xl"
        image={image}
        imageAlign="left"
        imageFit="contain"
        className="sm:h-96 hidden sm:flex"
        imageClassName="h-full w-full p-20"
      >
        <p>
          <strong>
            We are more than your hardwood lumber & plywood supplier
          </strong>{" "}
          — we are your partner in making your business successful.
        </p>
      </PreviewItem>
      <div className="flex sm:hidden flex-col bg-gray2">
        <div className="w-full bg-gray5 text-center py-2">
          <h2>About</h2>
        </div>
        <div className="flex flex-col gap-y-10 pt-5 pb-10 px-20 text-center leading-loose items-center">
          <GatsbyImage alt="" image={image} className="w-32" />
          <p>
            <strong>
              We are more than your hardwood lumber & plywood supplier
            </strong>{" "}
            — we are your partner in making your business successful.
          </p>
          <Link className="font-bold" to="/about">Our Story</Link>
        </div>
      </div>
    </ContentContainer>
  );
};

export default PreviewAbout;
