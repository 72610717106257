import React from "react";
import ContentContainer from "../container/ContentContainer";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

const AdvantageItem = ({ title, text, image }) => {
  
  return (
    //   <div>{text}</div>
    <div className="flex flex-col">
      <BackgroundImage
        tag="advantages"
        {...image}
        className="w-full h-28 bg-blend-darken bg-gray3 bg-opacity-80 text-white flex items-center justify-center"
      >
        <h3 className="font-bold px-8 py-5">{title}</h3>
      </BackgroundImage>
      <div className="bg-gray1 px-11 py-9 text-left flex-grow">{text}</div>
    </div>
  );
};

const Advantages = () => {
  const images = useStaticQuery(graphql`
    {
      file1: file(relativePath: { eq: "nwp1.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
        }
      }
      file2: file(relativePath: { eq: "nwp2.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
        }
      }
      file3: file(relativePath: { eq: "nwp3.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
        }
      }
      file4: file(relativePath: { eq: "nwp4.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
        }
      }
    }
  `);

  const advantages = [
    {
      title: "Hardwood Lumber & Plywood Reps",
      text:
        "A team of professionals dedicated to calling on custom cabinet shops, furniture manufacturers and wood working specialists of all sizes. Our reps are here to help your business utilize our products better so you can be more profitable.",
      image: convertToBgImage(getImage(images.file1)),
    },
    {
      title: "Distribution",
      text:
        "Our fleet of trucks covers deliveries from Tijuana Mexico to Ventura and out into the desert areas. Our large distribution area ensures that we can have a truck out to you promptly to meet your delivery needs. We also have available a Piggy Back Fork Lift trailer, which means no more hand unloading!",
      image: convertToBgImage(getImage(images.file2)),
    },
    {
      title: "Customer Service",
      text:
        "Reliable, friendly, bi-lingual, front office staff who are always available.",
      image: convertToBgImage(getImage(images.file3)),
    },
    {
      title: "Warehouse",
      text:
        "A 60,000+ square foot facility with a knowledgeable and efficient staff.",
      image: convertToBgImage(getImage(images.file4)),
    },
  ];

  return (
    <ContentContainer className="max-w-full sm:bg-gray2 sm:mx-5 flex flex-col items-center">
      <div className="w-full max-w-5xl mt-16 text-center mb-14 sm:mb-28">
        <h2>NWP ADVANTAGES</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 lg:gap-x-32 gap-y-10 lg:gap-y-20 mt-10 sm:mt-24">
          {advantages.map((advantage, index) => {
            
            return (
              <AdvantageItem
                key={`advantage${index}`}
                title={advantage.title}
                text={advantage.text}
                image={advantage.image}
              />
            );
          })}
        </div>
      </div>
    </ContentContainer>
  );
};

export default Advantages;
